<template>
  <div class="scenery">
    <div
      style="text-align: right; color: white"
      @click="$router.push('/home/menu/sceneryList')"
    >
      实景列表
    </div>
    <div style="margin-top: 40px">
      <van-form validate-first @submit="onFailed">
        <van-field
          v-model="formName"
          name="pattern"
          placeholder="请选择类型"
          @click="show = true"
          label="选择类型"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
          readonly
          is-link
        />
        <van-field
          v-model="form.mobl08"
          name="pattern"
          placeholder="请输入链接"
          label="链接地址"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
          is-link
          v-if="form.lbst03 == 2"
        />
        <van-field
          name="pattern"
          v-if="form.lbst03 == 1"
          placeholder="请选择类型"
          label="全景"
          input-align="right"
        >
          <template #input>
            <van-checkbox
              v-model="checkbox"
              v-if="form.lbst03 == 1"
              shape="square"
              >是/否</van-checkbox
            >
          </template>
        </van-field>
        <van-field
          v-model="form.mobl04"
          name="mobl04"
          placeholder="请输入拍摄名称"
          label="拍摄名称"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.mobl05"
          name="mobl05"
          placeholder="请输入拍摄地点"
          label="拍摄地点"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.mobl06"
          name="mobl06"
          placeholder="请选择拍摄时间"
          @click="showCalendar = true"
          label="拍摄时间"
          readonly
          is-link
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <div style="width: 100%; margin: 10px auto" v-if="form.lbst03 != 2">
          <van-uploader v-model="fileList" :after-read="sss" :max-count="1" />
        </div>

        <van-button type="info" native-type="submit" style="width: 100%"
          >提交</van-button
        >
      </van-form>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="类型"
        show-toolbar
        :columns="labelData"
        @confirm="onConfirmlabel"
        value-key="Name"
      />
    </van-popup>
    <van-calendar v-model="showCalendar" @confirm="onConfirmcalendar" />
  </div>
</template>

<script>
import { GetModelLableSets, UploadModelLable } from "@/api";
export default {
  name: "scenery",
  components: {},
  data() {
    return {
      showCalendar: false,
      show: false,
      fileList: [],
      checkbox: false,
      form: {
        mobl02: "",
        lbst03: 1,
        mobl03: "",
        mobl04: null,
        mobl05: null,
        mobl06: null,
        mobl07: 0,
        mobl08: null,
        mobl09: null,
        mobl10: null,
        mobl11: null,
        mobl12: null,
        mobl13: "",
        qid: "",
        file: "",
      },
      type: 1,
      formName: "",
      labelData: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    updateData() {
      this.getData();
      this.$emit("updateLoading", "实景上传");
    },
    onConfirmlabel(v) {
      this.formName = v.Name;
      this.form.mobl03 = v.lbst01;
      this.form.lbst03 = v.lbst03;
      this.show = false;
    },
    onConfirmcalendar(date) {
      this.form.mobl06 = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showCalendar = false;
    },
    async onFailed() {
      this.form.mobl02 = this.$store.state.pid;
      this.form.mobl13 = this.$store.state.uid;
      this.form.qid = this.$store.state.qid;
      this.form.mobl07 = this.checkbox ? 1 : 0;
      if (this.form.lbst03 == 2) {
        delete this.form.file;
      }
      // console.log(this.form);
      var formData = new FormData();
      for (const key in this.form) {
        formData.append(key, this.form[key]);
      }
      let res = await UploadModelLable(formData);
      console.log(res);
      res.Code > 0 ? this.$Toast("成功") : this.$Toast("失败");
      this.form = this.$options.data().form;
      this.fileList = this.$options.data().fileList;
    },
    sss(file) {
      this.form.file = file.file;
    },
    getData() {
      let params = {
        xid: this.$store.state.pid,
      };
      GetModelLableSets({ params }).then((res) => {
        this.form.mobl03 = res.Data[0].lbst01;
        this.form.lbst03 = res.Data[0].lbst03;
        this.formName = res.Data[0].Name;
        this.labelData = res.Data;
      });
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.scenery {
  padding: 0 40px;
}
</style>